<style lang="scss">

.c-sidebar-brand{
  background: #fff!important;
}

// .c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link
 .c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-toggle
{
    background: #4b92fc!important; 
    // color: #000!important;   
}

.c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link{
    // color: #fff;
    background: #c1c1c1!important;
    color:#000;
}


.c-sidebar-nav-link{
  // background: #57abfe!important;
  color: #000!important;
      border-bottom: 1px solid #afadad;
      background: #c1c1c1 !important;
}
.c-sidebar-nav-link, .c-sidebar-nav-dropdown-toggle{
  background: #ccc!important;
  border-bottom: 1px solid #afadad;

}
// .c-sidebar-nav-dropdown-toggle{
//   background: #4b92fc!important;
//         // border-bottom: 1px solid #4d4d4d;

// }

//ACA CORREGIO EN EL SPRINT 34
.c-sidebar-nav-link.router-link-exact-active.c-active:hover{
 
  font-weight: bold;
} 

// .c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link
.c-sidebar .c-sidebar-nav-dropdown.c-show a
// , 
// .c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-toggle
 {
    color: #000;
    font-weight: 500;
    font-size: 14px;
}

.c-sidebar .c-sidebar-nav-dropdown.c-show a:hover
{
    font-weight: bold!important;
}
.c-sidebar .c-sidebar-nav-dropdown a:hover
{
    font-weight: bold!important;
    color: #000!important;
}

.c-sidebar-nav-link, .c-sidebar-nav-dropdown-toggle{

font-weight: 500;
}
.router-link-exact-active{
font-weight: 500;
}


.c-sidebar-nav-dropdown-toggle::after {
   
    flex: 0 13px!important;
    height: 13px!important;
    
}

ul.c-sidebar-nav{
  background: #c1c1c1 !important;
}

.btn__mobile__close{
  border-radius: 0!important;
    background: #4b92fc !important;
    cursor: pointer;
    margin: 0!important;
    font-size: 18px;
    letter-spacing: 1px;
    padding-top:0.8em !important;
    padding-bottom: 0.8em !important;
}
.btn__mobile__close:hover{
    background: #000!important;
    color: #fff!important;
}


.c-sidebar .c-sidebar-nav-link, .c-sidebar .c-sidebar-nav-dropdown-toggle {
    color: #000;
}



@media only screen and (max-width: 590px) {
  .c-sidebar .c-sidebar-nav-link, .c-sidebar .c-sidebar-nav-dropdown-toggle {
   
    font-size: 20px!important;
}
}


@media only screen and (max-width: 460px) {
  

html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right), html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right) {
    margin-left: 0;
    // width: 52%;
    width: 100%;
}
}

  
</style>

<template>
  <CSidebar 
  style="background-color: #21458d; "
    fixed 
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="" to="/">
      <img src="../assets/img/logo_negro.png" width="120em;"/>
    </CSidebarBrand>
    <!-- <CSidebarBrand class="d-md-down-none" to="/">
      <img src="../assets/img/logo_club_azul_2.png" width="120em;"/>
    </CSidebarBrand> -->

    <CRenderFunction flat :content-to-render="$options.nav"/>
    <!-- <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    /> -->

    <button class="btn btn__mobile__close" @click="closeSesion()">Salir 
       <!--<img src="../assets/img/icon_exit.png" style="width: 26px" />-->
       <CIcon   style="color:#fff;font-weigth:bold;font-size:1.5em;" name="cilExitToApp"/>
       </button>

  </CSidebar>
</template>

<script>
import nav from './_nav'

export default {
  name: 'TheSidebar',
  nav,
  computed: {
    show () {
      return this.$store.state.sidebarShow 
    },
    minimize () {
      return this.$store.state.sidebarMinimize 
    },
    closeSesion () {
      localStorage.setItem(`${process.env.VUE_APP_IDENTIFIER_LOCAL_STORAGE}sesion-appweb-comercio`,"0")
      this.$router.push("/login")
    }
  }
}
</script>
