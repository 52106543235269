export default [
  {
    _name: "CSidebarNav",
    _children: [
      {
        _name: "CSidebarNavItem",
        name: "Inicio",
        to: "/dashboard",
        icon: "cil-speedometer",
      },
      //Consultas
      {
        _name: "CSidebarNavDropdown",
        name: "Consultas",
        to: "/theme/colors",
        icon: "cil-drop",
        items: [
          {
            name: "Estado de suscripción",
            to: "/dashboard/consultas/estado-suscripcion",
          },
          {
            name: "Consulta de renovación",
            to: "/dashboard/consultas/consulta-renovacion",
          },
          {
            name: "Comprobante de pago",
            to: "/dashboard/consultas/comprobante-pago",
          },
          {
            name: "Estado de anulación",
            to: "/dashboard/consultas/estado-anulacion",
          },

          {
            name: "Estado devolución de dinero",
            to: "/dashboard/consultas/devolucion-dinero",
          },
          {
            name: "Estado cobro doble",
            to: "/dashboard/consultas/cobro-doble",
          },
          {
            name: "Geniogramista",
            to: "/dashboard/consultas/geniogramista",
          }
        ],
      },
      //club comercio
      {
        _name: "CSidebarNavDropdown",
        name: "Club El Comercio",
        to: "/theme/colors",
        icon: "cil-drop",
        items: [
          {
            name: "Como ser parte del club",
            to: "/dashboard/club-comercio/ser-parte-del-club",
          },
          {
            name: "Promociones",
            to: "/dashboard/club-comercio/promociones",
          },
          {
            name: "Beneficios",
            to: "/dashboard/club-comercio/beneficios",
          },
          {
            name: "Cambiar un beneficiario",
            to: "/dashboard/club-comercio/cambiar-beneficiario",
          },
          {
            name: "Actualizar mis datos",
            to: "/dashboard/club-comercio/actualizar-datos",
          },
          {
            name: "Reclamo club suscritores",
            to: "/dashboard/club-comercio/reclamo-club-suscriptores",
          }
        ]
      },
      //peru quizosco
      {
        _name: "CSidebarNavDropdown",
        name: "PerúQuiosco",
        to: "/theme/colors",
        icon: "cil-drop",
        items: [
          {
            name: "Consulta de PQ",
            to: "/dashboard/peru-quiosco/consulta-pq",
          },
          {
            name: "Activacion de cuenta",
            to: "/dashboard/peru-quiosco/activar-cuenta-pq",
          },
          {
            name: "Cambio de contraseña",
            to: "/dashboard/peru-quiosco/cambiar-contrasena-pq",
          },
          {
            name: "Problemas de acceso",
            to: "/dashboard/peru-quiosco/problemas-acceso-pq",
          }
        ]
      },
      //Actualizacion
      {
        _name: "CSidebarNavDropdown",
        name: "Actualiza",
        to: "/theme/colors",
        icon: "cil-drop",
        items: [
          {
            name: "Nombre o razon social",
            to: "/dashboard/actualizacion/nombre-razon-social",
          },
          {
            name: "Documento identidad",
            to: "/dashboard/actualizacion/documento-identidad",
          },
          {
            name: "Datos de contacto",
            to: "/dashboard/actualizacion/datos-personales",
          },
          {
            name: "Cambio dirección",
            to: "/dashboard/actualizacion/cambio-direccion",
          },
          {
            name: "Forma de entrega",
            to: "/dashboard/actualizacion/cambio-forma-entrega",
          },
          {
            name: "Tarjeta de pagos",
            to: "/dashboard/actualizacion/tarjeta-pagos",
          },
          {
            name: "Activar cuenta",
            to: "/dashboard/actualizacion/activacion-cuenta",
          },
          {
            name: "Actualizar contraseña",
            to: "/dashboard/actualizacion/contraseña",
          }
        ],
      },
      //Reclamos de reparto
      {
        _name: "CSidebarNavDropdown",
        name: "Reclamos",
        to: "/theme/colors",
        icon: "cil-drop",
        items: [
          {
            name: "No me llegó el diario",
            to: "/dashboard/reclamos/no-me-llego-el-diario",
          } /*+*/
          , {
            name: "Edición maltratada",
            to: "/dashboard/reclamos/edicion-maltratada",
          },
          {
            name: "Entrega incompleta",
            to: "/dashboard/reclamos/entrega-incompleta",
          },
          {
            name: "Horario de entrega",
            to: "/dashboard/reclamos/horario-de-entrega",
          },
          {
            name: "Forma de entrega",
            to: "/dashboard/reclamos/forma-de-entrega",
          }
        ],
      },
      //Cancelar suscripcion
      {
        _name: "CSidebarNavItem",
        name: "Cancelar suscripción",
        to: "/dashboard/cancelar-suscripcion",
        icon: "cil-speedometer",
      }

    ],
  },
];
