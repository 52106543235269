<template>
  <div class="c-app">
    <TheSidebar/>
    <CWrapper>
      <TheHeader/>
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
      <TheFooter/>
    </CWrapper>
  </div>
</template>

<script>
import TheSidebar from './TheSidebar'
import TheHeader from './TheHeader'
import TheFooter from './TheFooter'

export default {
  name: 'TheContainer',
  components: {
    TheSidebar,
    TheHeader,
    TheFooter
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.c-body {
  flex: 1 1 auto;  
}

.card-body {
    
    padding: 0px!important;
}

</style>



<style lang="scss">




@media only screen and (max-width: 590px) {
  body{
  font-size: 17px;
}

}

a {
  font-weight: bold;
}
a.router-link-active {
  color: #000 !important;
}

.active span {
  color: #4b92fc!important;
  font-weight: bold;
}

.c-app {
  background: transparent;
}

.c-header {
  border: none;
}

.c-body {
  border: none;
  background: transparent;
  
}

.c-main {
  background: transparent;
  border: none;
  padding-top: 0px !important;
}

///
.card {
  border: none;
  background: transparent;
}
.card-header {
  border: none;
  background: transparent;
  color: #21458d;
  font-size: 22px;
}


label {
  font-size: 18px;
  font-weight: 600;
  color: #4b92fc;
}

.avatar__alicia {
  position: fixed;
  bottom: 50px;
  right: 15px;
  width: 200px;
  z-index: -1;
}



.container__detalle{
  padding: 15px;
    border: 1px solid #ccc;
    // margin: 15px;
    border-radius: 4px;
}

.avatar__alicia__mobile{
  display: none;
      position: relative;
          width: 180px;
    
      left: 50%;
  }
@media only screen and (max-width: 960px) {

  .avatar__alicia {
    display: none;
  }

  .avatar__alicia__mobile{
      display: block;
      position: relative;
    
      left: 50%;
  }
  
}
@media only screen and (max-width: 420px) {

//   .container__message {
//     overflow: hidden;
// }

  .card-body {
    padding: 0 !important;
}
//   .avatar__alicia {
//      display: block;
//       position: relative;
    
//       left: 50%;
//   }

//   .avatar__alicia__message {
//     position: absolute !important;
//     width: 190px !important;
//     bottom: -179px !important;
// }

  
}

.modal-header{
    background: rgb(33, 69, 141)!important;
    color: #fff!important;
}

.modal-header .close {
  color: #fff!important
}
</style>

