<style lang="scss">
.c-header {
  background: #ccc !important;
}

.c-header .c-subheader {
    border-top: none!important;
}

@media (max-width: 993px) {
  .c-header {
    background: rgb(33, 69, 141) !important;

    color: #fff !important;
    .c-header-nav-link {
      color: #fff !important;
    }
  }

  .c-header .c-header-toggler-icon {
    background-image: url("../assets/img/menu_icon.png");
    background-size: 40px;
  }
}
@media (max-width: 560px) {
  .c-header {
    img{
      width: 160px!important;
    margin: 10px 0px!important;
    }
  }

  .img-logo{
    margin-left: -10px !important;
  }

}
</style>

<template>
  <CHeader fixed with-subheader light>
    <CHeaderBrand class="mx-auto d-lg-none text-left" to="/">
      <!-- <CIcon name="logo" height="48" alt="Logo"/> -->
      <img src="../assets/img/logo_club_azul_2.png" width="120px;" style="margin-left:-7em !important;" />
    </CHeaderBrand>

    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />

    <CHeaderNav class="d-md-down-none mr-auto"> </CHeaderNav>

    <CHeaderNav class="mr-4">
      <TheHeaderDropdownAccnt />
    </CHeaderNav>

    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    
    <!--<CSubheader class="px-3">
    </CSubheader>-->
  
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from "./TheHeaderDropdownAccnt";

export default {
  name: "TheHeader",
  components: {
    TheHeaderDropdownAccnt,
  },
};
</script>
