<style scoped>

  @media screen and (max-width: 600px) {
    .c-avatar{
      display:none;
    }
  }
</style>

<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <span style="margin-right:0.8em;" class="d-none d-sm-block">{{name.toUpperCase()}}</span>
        <div class="c-avatar">
          <img
            src="../assets/img/logo_comercio.png"
            class="c-avatar-img "
          />
        </div>
      </CHeaderNavLink>
    </template>

        <CDropdownItem @click="logout()">
      <CIcon name="cil-lock-locked" /> Salir
    </CDropdownItem>
  </CDropdown>
</template>

<script>

export default {
  name: 'TheHeaderDropdownAccnt',
  data () {
    return { 
      itemsCount: 42,
      name:localStorage.getItem("name")
    }
  },
  methods:{
    logout(){
      localStorage.setItem(`${process.env.VUE_APP_IDENTIFIER_LOCAL_STORAGE}sesion-appweb-comercio`,"0")
      this.$router.push("/login")
    }
  }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>
