<style lang="scss" scoped>
  @media only screen and (max-width: 590px) {
    .footer{
      display:none;
    }
  }
</style>

<template>
  <CFooter :fixed="false" class="footer" style="background-color:#21458d;flex:0 0 55px!important;border-top:0 !important;">
    <div>
      <span class="ml-1" style="color:#fff">Todos los derechos reservados &copy; {{new Date().getFullYear()}} Club El Comercio</span>
    </div>
    <div class="mfs-auto">
      <span class="mr-1" target="_blank" style="color:#fff">Developed by RPA Latam</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
